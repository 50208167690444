import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

import { ServiceActions, ServiceProviderActions } from 'app/store/actions';
import { Service, State } from 'app/store/models';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

import { ConfirmActionModalComponent } from './confirm-action-modal.component';

@Component({
  selector: 'app-admin-service',
  templateUrl: './admin-service.component.html',
  styleUrls: ['./admin-service.component.scss']
})
export class AdminServiceComponent implements OnDestroy {
  loadingServiceProviders$: Observable<boolean>;
  loadingServices$: Observable<boolean>;
  savingService$: Observable<boolean>;
  serviceProviderOptions$: Observable<{ value: string; label: string }[]>;
  serviceProviderMap: { [k: string]: string } = {};
  services$: Observable<Service[]>;
  activeService: Service | null = null;
  serviceForm: UntypedFormGroup;
  modalInstance: any;
  descriptionMaxLength = 300;

  page$ = new BehaviorSubject<number>(1);
  pages$: Observable<number>;
  pageSize = 20;
  searchQuery?: string;
  searchQueryDebounced$ = new BehaviorSubject<string|undefined>(undefined);

  @ViewChild('serviceModal', { static: true })
  serviceModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private actions$: Actions,
  ) {
    this.loadingServiceProviders$ = this.store.select(x => x.admin.serviceProvider.loadingItems);
    this.loadingServices$ = this.store.select(x => x.admin.service.loadingItems);
    this.savingService$ = this.store.select(x => x.admin.service.savingItem);
    this.store.select(x => x.admin.serviceProvider.items).pipe(
      filter(notNull),
      startWith([]),
      untilDestroyed(this),
    ).subscribe(serviceProviders => serviceProviders.forEach(
        x => this.serviceProviderMap[x.id] = x['@id'] ? x['@id'] : '/api/v1/service_provider/' + x.id,
    ));
    this.serviceProviderOptions$ = this.store.select(
      x => (x.admin.serviceProvider.items || [])
        .map(serviceProvider => ({ label: serviceProvider.name, value: serviceProvider.id })),
    );
    this.services$ = this.store.select(x => x.admin.service.items).pipe(
      filter(notNull),
      startWith([]),
    );
    this.serviceForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      serviceProvider: [null, Validators.required],
      label: [null, Validators.required],
      description: [null, Validators.maxLength(this.descriptionMaxLength)],
      url: [null],
      icon: [null],
      showOnDashboard: [null],
      vatRatePercentage: [null, Validators.required],
      vatRateLocalVat: [null],
      eoInvoiceCode: [null],
      eoSameCountryCode: [null],
      eoDifferentEuCountryCode: [null],
      eoDifferentNonEuCountryCode: [null],
      eoLocalVatCountryCode: [null],
      eoCostCenter: [null],
      eoCostUnit: [null],
      eoDoNotGroup: [null],
    });
    this.store.dispatch(ServiceProviderActions.loadServiceProviders());
    this.store.dispatch(ServiceActions.loadServices({
        page: 1,
        pageSize: this.pageSize,
      }));
    this.actions$.pipe(
      ofType(
        ServiceActions.createServiceSuccess,
        ServiceActions.updateServiceSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      this.modalInstance.dismiss();
    });
    this.actions$.pipe(
      ofType(
        ServiceActions.createServiceSuccess,
        ServiceActions.deleteServiceSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      if (this.modalInstance) {
        this.modalInstance.dismiss();
      }
      this.page$.next(this.page$.value);
    });

    this.pages$ = this.store.select(x => x.admin.service).pipe(map(x => {
      return Math.ceil((x.totalItems || 0) / this.pageSize);
    }));
    this.page$.subscribe(page => {
      this.store.dispatch(ServiceActions.loadServices({
        page,
        pageSize: this.pageSize,
        query: this.searchQueryDebounced$.getValue(),
      }));
    });
    this.searchQueryDebounced$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    )
    .subscribe(() => this.page$.next(1));
  }

  newService() {
    this.activeService = null;
    this.serviceForm.reset();
    this.modalInstance = this.modalService.open(this.serviceModal, { size: 'lg' });
  }

  editService(service: Service) {
    this.activeService = service;
    this.serviceForm.reset();
    this.serviceForm.patchValue({
      ...this.activeService,
      serviceProvider: this.activeService.serviceProvider.id,
      vatRatePercentage: this.activeService.vatRatePercentage
        ? parseFloat(this.activeService.vatRatePercentage)
        : null,
    });
    this.modalInstance = this.modalService.open(this.serviceModal, { size: 'lg' });
  }

  deleteService(service: Service) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.title = marker('Are you sure you want to delete this service?');
    modalRef.componentInstance.message = marker('This action will delete the service ') + service.label;
    modalRef.componentInstance.confirmButtonText = marker('Delete');
    modalRef.result.then(() => this.store.dispatch(ServiceActions.deleteService({ serviceId: service.id })));
  }

  onCreateServiceSubmit() {
    if (this.serviceForm.invalid) {
      this.serviceForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(ServiceActions.createService({
      service: {
        ...this.serviceForm.value,
        serviceProvider: this.serviceProviderMap[this.serviceForm.value.serviceProvider],
        vatRatePercentage: (this.serviceForm.value.vatRatePercentage !== null)
          ? this.serviceForm.value.vatRatePercentage.toFixed(2)
          : null,
        vatRateLocalVat: !!this.serviceForm.value.vatRateLocalVat,
        eoDoNotGroup: !!this.serviceForm.value.eoDoNotGroup,
      },
    }));
  }

  onEditServiceSubmit() {
    if (this.serviceForm.invalid || this.activeService === null) {
      this.serviceForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(ServiceActions.updateService({
      service: {
        ...this.serviceForm.value,
        id: this.activeService.id,
        serviceProvider: this.serviceProviderMap[this.serviceForm.value.serviceProvider],
        vatRatePercentage: (this.serviceForm.value.vatRatePercentage !== null)
          ? this.serviceForm.value.vatRatePercentage.toFixed(2)
          : null,
        vatRateLocalVat: !!this.serviceForm.value.vatRateLocalVat,
        eoInvoiceCode: this.serviceForm.value.eoInvoiceCode || null,
        eoSameCountryCode: this.serviceForm.value.eoSameCountryCode || null,
        eoDifferentEuCountryCode: this.serviceForm.value.eoDifferentEuCountryCode || null,
        eoDifferentNonEuCountryCode: this.serviceForm.value.eoDifferentNonEuCountryCode || null,
        eoLocalVatCountryCode: this.serviceForm.value.eoLocalVatCountryCode || null,
        eoCostCenter: this.serviceForm.value.eoCostCenter || null,
        eoCostUnit: this.serviceForm.value.eoCostUnit || null,
        eoDoNotGroup: !!this.serviceForm.value.eoDoNotGroup,
      },
    }));
  }

  onVatRateLocalVatChange(): void {
    const vatRateLocalVat = !!this.serviceForm.value.vatRateLocalVat;

    if (vatRateLocalVat) {
      this.serviceForm.get('eoLocalVatCountryCode')?.setValidators([Validators.required]);
    } else {
      this.serviceForm.get('eoLocalVatCountryCode')?.setValidators(null);
    }

    this.serviceForm.get('eoLocalVatCountryCode')?.updateValueAndValidity();
  }

  ngOnDestroy() { }
}
